import React, { useEffect } from "react";
import resumeFile from "../documents/resume.pdf";
import Testimonials from "./Testimonials";
import data from "../config/info.json";
import {
  getDarkBg,
  getJsonObject,
  getLightBg,
  getStringyJson,
  getStringyNestedJson,
} from "../config/config";
import { getImage } from "../config/firebaseConfig";

const AboutUs = ({ classicHeader, darkTheme, compNo }) => {
  let patients, clinics, years, encounters, imgsPath;
  const data = getJsonObject("aboutus");
  const setContent = data.map((info) => {
    imgsPath = info["imgs"];
    return (
      <React.Fragment key={info.heading}>
        <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>
          {info["heading"].split(info["headingHighlight"])[0]}
          <span className="text-primary">{info["headingHighlight"]}</span>
          {info["heading"].split(info["headingHighlight"])[1]}
        </h2>
        <p className={darkTheme ? "text-white-50" : ""}>
          {info["paragraphFirst"]}
        </p>
        <p className={darkTheme ? "text-white-50" : ""}>
          {info["paragraphSecond"]}
        </p>
      </React.Fragment>
    );
  });

  const DisplayAchievements = () => {
    data.forEach((result) => {
      result["achivements"].forEach((value) => {
        patients = value["patients"];
        clinics = value["clinics"];
        years = value["years"];
        encounters = value["encounters"];
      });
    });
  };

  DisplayAchievements();

  return (
    <section
      id="about"
      className={
        "section " + (darkTheme ? getDarkBg(compNo) : getLightBg(compNo))
      }
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About US
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Us More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            {setContent}
          </div>
          {/* About me content end */}
          {/* about me personal details start */}
          <div className="col-lg-5 col-xl-4">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="3000" // Set the interval to 3000 ms (3 seconds)
            >
              <div className="carousel-indicators">
                {imgsPath.map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {imgsPath.map((imgPath, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={getImage(imgPath)}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12 mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  {years}+<span>{}</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12 mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{patients}</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Patients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12 mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{encounters}</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Encounters Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12 mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{clinics}</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Clinics
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
