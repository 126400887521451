import React, { useState, useEffect } from "react";
import { getDarkBg, getLightBg, getStringyJson } from "../config/config";

const getClinicTimings = () => {
  let data = getStringyJson("clinicDetails");
  return JSON.parse(data);
};

const Schedule = ({
  classicHeader,
  darkTheme,
  compNo,
  setFutureClinicDates,
  futureClinicDates,
}) => {
  const [sortedClinics, setSortedClinics] = useState([]);
  let data = getClinicTimings();
  useEffect(() => {
    const now = new Date();
    const clinicData = getClinicTimings();

    const filteredClinics = clinicData.filter(
      (info) => combineDateTime(info.date, info.endTime) > now
    );

    const sortedClinics = filteredClinics.sort(
      (a, b) =>
        combineDateTime(a.date, a.startTime) -
        combineDateTime(b.date, b.startTime)
    );
    setSortedClinics(sortedClinics);
    const date= sortedClinics.length > 0 ? [getFormattedDate(sortedClinics[0].date)] : [];
    setFutureClinicDates(date);
  }, []);

  const getFormattedDate = (input) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const obj = new Date(input + " 00:00:00");

    const day = days[obj.getDay()];
    const month = months[obj.getMonth()];
    const date = obj.getDate();

    return day + " " + month + ", " + date;
  };

  const combineDateTime = (date, time) => {
    const [hours, minutes] = time.match(/(\d+):(\d+)(\w{2})/).slice(1, 3);
    const isPM = /PM$/.test(time);
    const hour24 =
      isPM && parseInt(hours) < 12 ? parseInt(hours) + 12 : parseInt(hours);
    return new Date(`${date}T${hour24}:${minutes}:00`);
  };
  const now = new Date();
  data = data
    .filter((info) => combineDateTime(info.date, info.endTime) > now)
    .sort(
      (a, b) =>
        combineDateTime(a.date, a.startTime) -
        combineDateTime(b.date, b.startTime)
    );
  const DisplayData = data.map((info, index) => (
    <tr key={index + 1}>
      {/* <th scope="row">{index + 1}</th> */}
      <td>{getFormattedDate(info.date)}</td>
      <td>{info.startTime + " - " + info.endTime}</td>
      <td>{info.description}</td>
    </tr>
  ));
  return (
    <section
      id="schedule"
      className={
        "section " + (darkTheme ? getDarkBg(compNo) : getLightBg(compNo))
      }
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Schedule
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Upcoming Clinics
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        <div className="table-responsive rounded-lg scroll-table">
          <table className={"table table-sm table-striped text-center"}>
            <thead
              className={
                "" + (darkTheme ? "table-dark text-primary" : "table-primary")
              }
            >
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody
              className={
                "table-hover " + (darkTheme ? "table-dark" : "table-light")
              }
            >
              {DisplayData}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Schedule;
