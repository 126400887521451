import React from "react";
import { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link as ScrollLink } from "react-scroll";
import { getJson, getJsonObject } from "../config/config";
import { getImage } from "../config/firebaseConfig";
import { Link, useNavigate } from "react-router-dom";

const ClassicHeader = ({ handleNavClick, darkTheme, setDarkTheme }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const navigate = useNavigate();

  const changeTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");
    if (header) {
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const handleNavLinkClick = (section) => {
    navigate(`#${section}`, { replace: true });
    setIsNavModalClose(true);
  };

  return (
    <header id="header" className="sticky-top-slide">
      <nav
        className={
          "primary-menu navbar navbar-expand-lg navbar-light bg-dark-gray border-bottom-0 sticky-top navbar-small " +
          (stickyHeader ? "sticky-on" : "")
        }
      >
        <div className="container-fluid position-relative g-lg-4">
          <div className="col-auto col-lg-2 align-middle">
            <ScrollLink
              smooth
              duration={500}
              style={{ cursor: "pointer", maxWidth: "100%", height: "auto" }}
              className="logo"
              to="home"
              title="GHMC"
              onClick={(e) => {
                handleNavLinkClick("home");
              }}
            >
              {/* <span className="text-white fw-600 text-8 align-middle align-logo"> */}
                <img
                  src={getImage(getJson("ghmcTextLogo"))}
                  alt="GHMC Logo"
                  className="align-middle logo-img"
                  style={{
                    width: "auto",
                    maxHeight: "60px",
                    height: "auto",
                  }}
                ></img>
              {/* </span> */}
            </ScrollLink>
          </div>
          {/* TODO: Future Version */}
          {/* <span className="logo text-white fw-600 text-8 align-middle align-logo">
            <a
              href={getJson("fremontGurudwaraSiteLink")}
              className="text-white fw-600 text-8 align-middle me-3 mobile-second-logo"
            >
              <img
                src={getImage(getJson("fremontGurudwaraLogo"))}
                alt="Fremont Gurudwara Logo"
                className="gurudwara-logo-img align-middle"
                style={{
                  width: "auto",
                  maxHeight: "60px",
                  height: "auto",
                }}
              ></img>
            </a>
          </span> */}
          <div className="d-flex align-items-center order-lg-2">
            {/* This is For Showing Second Logo on the right side of the navbar on Desktop */}
            {/* <a
              href="https://fremontgurdwara.org/"
              className="text-white fw-600 text-8 align-middle me-3 mobile-second-logo"
            >
              <img
                src={getImage(getJson("fremontGurudwaraLogo"))}
                alt="Fremont Gurudwara Logo"
                className="align-middle"
                style={{
                  width: "auto",
                  maxHeight: "60px",
                  height: "auto",
                }}
              ></img>
            </a> */}
          </div>
          <div className="col col-lg-8 navbar-accordion">
            <button
              onClick={() => setIsNavModalClose(!isNavModalClose)}
              className={
                isNavModalClose
                  ? "navbar-toggler ms-auto"
                  : "navbar-toggler ms-auto show"
              }
              id="navbar-toggler"
              type="button"
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                isNavModalClose
                  ? "collapse navbar-collapse justify-content-center "
                  : "show navbar-collapse justify-content-center"
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="home"
                    onClick={() => handleNavLinkClick("home")}
                  >
                    Home
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="about"
                    onClick={() => handleNavLinkClick("about")}
                  >
                    About
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="services"
                    onClick={() => handleNavLinkClick("services")}
                  >
                    Services
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="testimonial"
                    onClick={() => handleNavLinkClick("testimonial")}
                  >
                    Testimonials
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="schedule"
                    onClick={() => handleNavLinkClick("schedule")}
                  >
                    Schedule
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="portfolio"
                    onClick={() => handleNavLinkClick("portfolio")}
                  >
                    Team
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="contact"
                    onClick={() => handleNavLinkClick("contact")}
                  >
                    Contact
                  </ScrollLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 d-flex justify-content-end align-items-center ">
            {/* This is For Showing Second Logo on the right side of the navbar on Desktop */}
            <a
              href="https://fremontgurdwara.org/"
              className="text-white fw-600 text-8 align-middle me-3 desktop-second-logo"
            >
              <img
                src={getImage(getJson("fremontGurudwaraLogo"))}
                alt="Fremont Gurudwara Logo"
                className="align-middle"
                style={{
                  width: "auto",
                  maxHeight: "60px",
                  height: "auto",
                }}
              ></img>
            </a>
            <ul className="social-icons social-icons-dark mode d-flex align-items-center">
              <li className="switch-btn align-middle me-3">
                <Tooltip
                  text={darkTheme ? "Dark Mode" : "Light Mode"}
                  placement="top"
                >
                  <a onClick={changeTheme} style={{ cursor: "pointer" }}>
                    <i
                      className={
                        darkTheme ? "fa-solid fa-moon" : "fa-solid fa-sun"
                      }
                    />
                  </a>
                </Tooltip>
              </li>
              {getJson("twitterUrl") && (
                <li className="social-icons-twitter social-tab me-2">
                  <Tooltip text="Twitter" placement="top">
                    <a
                      href={getJson("twitterUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {getJson("facebookUrl") && (
                <li className="social-icons-facebook me-2">
                  <Tooltip text="Facebook" placement="top">
                    <a
                      href={getJson("facebookUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {getJson("instagramUrl") && (
                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                      href={getJson("instagramUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default ClassicHeader;
