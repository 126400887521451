import React from "react";

import Slider from "react-slick";
import { getDarkBg, getJsonObject, getLightBg } from "../config/config";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { getImage } from "../config/firebaseConfig";

const exsists = (val) => {
  return val === undefined || val == null || val.length <= 0 ? true : false;
};
const Testimonials = ({ classicHeader, darkTheme, compNo }) => {
  const reviews = getJsonObject("reviews");

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={
        "section " + (darkTheme ? getDarkBg(compNo) : getLightBg(compNo))
      }
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Feedback
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Testimonials
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={getImage(value.src)}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span
                        className={
                          " fw-500 " + (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  {exsists(value.audio) ? (
                    ""
                  ) : (
                    <AudioPlayer
                      className={"card-rounded"}
                      src={"/" + value.audio}
                      onPlay={(e) => console.log("onPlay")}
                      showJumpControls={false}
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                    ></AudioPlayer>
                  )}
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    {exsists(value.desc) ? "" : '"' + value.desc + '"'}
                  </p>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
